import { Rep } from '../../hooks/repsData';

const repType = (rep: Rep) => {
  return rep.type === 'sen'
    ? 'Senator'
    : rep.gender === 'M'
      ? 'Congressman'
      : 'Congresswoman';
};

export const introText =
  'Hi, my name is [Your Name], and I live in [Your Address],';

export const constituent = (rep: Rep) => {
  return "I'm a constituent of " + repType(rep) + ' ' + rep.last_name;
};

export const mainText = (rep: Rep) => {
  const difference = +new Date() - +new Date('2023-10-07T06:30:00');
  const days = Math.floor(difference / (1000 * 60 * 60 * 24));

  const supportMessage =
    'It’s been ' +
    days +
    ' days and 90 people are still held hostage by Hamas in Gaza. I’m reaching out to urge the ' +
    repType(rep) +
    ' to continue pressuring their colleagues, the Biden administration, and Secretary Blinken to demand the safe release of our loved ones held in Gaza.';

  return supportMessage;
};

export const voicemail =
  '**  If the line is busy or off hours, just leave a voicemail!';

const phoneRegex = /^(1|)?(\d{3})(\d{3})(\d{4})$/;

export const cleanPhone = (phone: string) => {
  return ('' + phone).replace(/\D/g, '');
};

export const formatPhone = (phone: string, addCountryCode = false) => {
  const match = cleanPhone(phone).match(phoneRegex);
  if (match) {
    var intlCode = match[1] || addCountryCode ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return phone;
};
