import styled, { css } from 'styled-components';
import { MEDIA_QUERIES } from '../common/mediaQueries';
import { HostagesCarousel } from '../hostagesCarousel/HostagesCarousel';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  gap: 40px;
  padding: 100px 0;
  max-width: min(1200px, calc(100vw - 100px));

  ${MEDIA_QUERIES.laptop(css`
    padding: 60px 0;
    grid-row: 2/3;
    max-width: 700px;
  `)}

  ${MEDIA_QUERIES.tablet(css`
    padding: 50px 0;
    grid-row: 2/3;
    max-width: calc(100vw - 100px);
    gap: 20px;
  `)}

  ${MEDIA_QUERIES.smallTablet(css`
    grid-template-columns: 1fr;
    max-width: calc(100vw - 40px);
    padding: 40px 0;
    gap: 30px;
  `)}
`;

const TextWrapper = styled.div`
  align-self: end;

  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 30px;

  ${MEDIA_QUERIES.laptop(css`
    align-items: center;
    grid-column: 1/3;
  `)}

  ${MEDIA_QUERIES.smallTablet(css`
    grid-column: 1/2;
    gap: 10px;
  `)}
`;

const Heading = styled.div`
  font-size: 24px;
  color: var(--red);
  font-weight: 700;

  ${MEDIA_QUERIES.smallTablet(css`
    font-size: 20px;
  `)}

  ${MEDIA_QUERIES.mobile(css`
    font-size: 18px;
  `)}
`;

const BringThemHomeCallout = styled.div`
  background-color: var(--red);
  border-radius: 50px;
  border-bottom-left-radius: 0;
  font-size: 60px;
  font-weight: 400;
  line-height: 1;
  color: var(--white);
  padding: 18px 36px;

  ${MEDIA_QUERIES.smallTablet(css`
    font-size: 45px;
    padding: 14px 28px;
    border-radius: 28px;
    border-bottom-left-radius: 0;
  `)}

  ${MEDIA_QUERIES.mobile(css`
    font-size: 34px;
    padding: 12px 22px;
    border-radius: 22px;
    border-bottom-left-radius: 0;
  `)}

  ${MEDIA_QUERIES.smallMobile(css`
    font-size: 30px;
    padding: 12px 22px;
    border-radius: 22px;
    border-bottom-left-radius: 0;
  `)}
`;

const WhyTextWrapper = styled.div`
  align-self: start;

  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  ${MEDIA_QUERIES.laptop(css`
    justify-content: center;
    align-self: center;
  `)}

  ${MEDIA_QUERIES.smallTablet(css`
    grid-column: 1/2;
    justify-content: center;
    align-self: start;
    max-width: 350px;
  `)}

  ${MEDIA_QUERIES.mobile(css`
    max-width: min(280px, 90vw);
  `)}
`;
const Why = styled.div`
  color: var(--black);
  font-size: 24px;

  ${MEDIA_QUERIES.smallTablet(css`
    font-size: 20px;
  `)}

  ${MEDIA_QUERIES.mobile(css`
    font-size: 18px;
  `)}
`;

const Content = styled.div`
  color: var(--black);
  text-align: left;
  font-family: var(--font-secondary);
  font-weight: 400;
  & p {
    margin-bottom: 10px;
    line-height: 1.4;
  }
`;

export const BringThemHome = () => {
  return (
    <Wrapper>
      <HostagesCarousel />
      <TextWrapper>
        <Heading>1 min. of your day and a simple message:</Heading>
        <BringThemHomeCallout>BRING THEM HOME NOW</BringThemHomeCallout>
      </TextWrapper>
      <WhyTextWrapper>
        <Why>Why?</Why>
        <Content>
          <p>
            So far we've been blessed with the release of 113 hostages. It is
            clear that the USA is a key player in the negotiations and will
            remain heavily involved in all negotiations going forward.
          </p>
          <p>
            Only by making thousands of direct, urgent and repeated appeals to
            your elected representatives can actionable pressure be placed on
            the U.S. government to prioritize the return all 90 hostages to
            their loved ones.
          </p>
          <p>
            If we all reach out to our elected officials—now and constantly—we
            stand a much better, realistic chance of ensuring that the return
            all 90 hostages continues to remain a top U.S. and global priority.
          </p>
        </Content>
      </WhyTextWrapper>
    </Wrapper>
  );
};
